import React from "react";
import { Link } from "react-router-dom";
import { langs } from "../../locale/localization";
import { useSelector } from "react-redux";
import { getApolloClient } from "../../actions/appollo";
import { GET_FOOTER_OPTIONS, GET_MENUS } from "../../actions/query";
import { useQuery } from "@apollo/client";

function Footer(props) {
  // const locale = props.locale;
  const date = new Date();
  const locale = langs(useSelector((state) => state.langReducer).locale);
  const style = useSelector((state) => state.styleReducer).style || null;
  const lang = useSelector((state) => state.langReducer).locale;



  return (
    <div className="footer">
      <div className="container">
   
        <div className="footer-bottom">
          <p>
          © {date.getFullYear()}{" "}
            <a href="https://iqb-tool.com/" className="decoration">
            IQB Tool
            </a>{" "}
            Oké gereserveerd.
          </p>
          <p>
          Gebouwd️‍ met ❤ door <a href="https://www.minebytes.com/">Minebytes</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
